import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-100"
}
const _hoisted_2 = { class: "row mb-6" }
const _hoisted_3 = { class: "col-lg-8" }
const _hoisted_4 = {
  class: "image-input image-input-outline",
  "data-kt-image-input": "true",
  style: {"background-image":"url(media/avatars/blank.png)"}
}
const _hoisted_5 = { class: "d-flex flex-stack pt-10" }
const _hoisted_6 = {
  key: 1,
  class: "w-100"
}
const _hoisted_7 = { class: "pb-10 pb-lg-15" }
const _hoisted_8 = { class: "fw-bolder text-dark" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "row mb-6" }
const _hoisted_11 = { class: "col-lg-8" }
const _hoisted_12 = ["src"]
const _hoisted_13 = {
  key: 1,
  class: "col-12"
}
const _hoisted_14 = { class: "card card-bordered" }
const _hoisted_15 = { class: "card-body d-flex flex-column" }
const _hoisted_16 = {
  class: "text-dark fs-6 fw-bolder m-0 p-0",
  style: {"cursor":"pointer"}
}
const _hoisted_17 = {
  key: 1,
  class: "row mb-2 border-bottom border-gray-300",
  "data-kt-buttons": "true"
}
const _hoisted_18 = { class: "row" }
const _hoisted_19 = { class: "row mb-6" }
const _hoisted_20 = { class: "col-lg-8" }
const _hoisted_21 = ["src"]
const _hoisted_22 = {
  key: 2,
  class: "row mb-2 border-bottom border-gray-300",
  "data-kt-buttons": "true"
}
const _hoisted_23 = { class: "row" }
const _hoisted_24 = { class: "fw-bolder fs-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!

  return (_ctx.isExample)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", {
                class: "image-input-wrapper w-600px h-900px",
                style: _normalizeStyle(`background-image: url(media/ist/${Number(_ctx.istGroupName)}.jpg)`)
              }, null, 4)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "mr-2" }, null, -1)),
          _createElementVNode("div", null, [
            (_ctx.istGroupId == 'dummy')
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  type: "button",
                  class: "btn btn-lg btn-primary me-3",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.formSubmit && _ctx.formSubmit(...args)))
                }, "Selesai"))
              : (_openBlock(), _createElementBlock("button", {
                  key: 1,
                  type: "button",
                  class: "btn btn-lg btn-primary me-3",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.formMulai && _ctx.formMulai(...args)))
                }, "Mulai"))
          ])
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questionCache, (item, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("h2", _hoisted_8, "No. " + _toDisplayString(item.IST_Order), 1),
              (_ctx.istGroupType=='PSO')
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("img", {
                          src: item.avatarImageUrl || 'media/avatars/blank.png',
                          alt: "Image Preview"
                        }, null, 8, _hoisted_12)
                      ])
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("p", _hoisted_16, _toDisplayString(item.IST_Question), 1)
                      ])
                    ])
                  ]))
            ]),
            (_ctx.istGroupType == 'OW')
              ? (_openBlock(), _createBlock(_component_Field, {
                  key: 0,
                  type: "text",
                  class: "form-control form-control-lg form-control-solid",
                  name: _ctx.istGroupId+'-'+item.IST_Question_Id+'ans',
                  value: "",
                  onInput: (e)=>_ctx.inputValues2(index, e.target.value),
                  placeholder: ""
                }, null, 8, ["name", "onInput"]))
              : (_ctx.istGroupType == 'PSO')
                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.IST_Choice, (item2, index2) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: index2,
                          class: "col-6",
                          style: {"margin-bottom":"20px"}
                        }, [
                          _createElementVNode("label", {
                            class: _normalizeClass(["btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4", _ctx.getActive(item2.ID, index)])
                          }, [
                            _createVNode(_component_Field, {
                              type: "radio",
                              class: "btn-check",
                              name: _ctx.istGroupId+'-'+item.IST_Question_Id+'-'+item2.ID,
                              value: item2.ID,
                              onClick: ($event: any) => (_ctx.setActive(item2.ID, index))
                            }, null, 8, ["name", "value", "onClick"]),
                            _createElementVNode("div", _hoisted_19, [
                              _createElementVNode("div", _hoisted_20, [
                                _createElementVNode("img", {
                                  src: item2.Description || 'media/avatars/blank.png',
                                  alt: "Image Preview"
                                }, null, 8, _hoisted_21)
                              ])
                            ])
                          ], 2)
                        ]))
                      }), 128))
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_22, [
                    _createElementVNode("div", _hoisted_23, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.IST_Choice, (item2, index2) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: index2,
                          class: _normalizeClass(_ctx.getClassBox(item2.Description)),
                          style: {"margin-bottom":"20px"}
                        }, [
                          _createElementVNode("label", {
                            class: _normalizeClass(["btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4", _ctx.getActive(item2.ID, index)])
                          }, [
                            _createVNode(_component_Field, {
                              type: "radio",
                              class: "btn-check",
                              name: _ctx.istGroupId+'-'+item.IST_Question_Id+'-'+item2.ID,
                              value: item2.ID,
                              onClick: ($event: any) => (_ctx.setActive(item2.ID, index))
                            }, null, 8, ["name", "value", "onClick"]),
                            _createElementVNode("span", _hoisted_24, _toDisplayString(item2.Description), 1)
                          ], 2)
                        ], 2))
                      }), 128))
                    ])
                  ]))
          ], 64))
        }), 128))
      ]))
}